<!-- 医療機関の詳細情報を表示し、編集、削除（または復元）するためのダイアログ -->
<template>
  <div v-if="$store.state.user.role.startsWith('management') || $store.state.user.role.startsWith('center')">
    <v-dialog
      v-if="currentItem"
      v-model="dialogModel"
      min-width="640px"
      :max-width="'90%'"
      @input="closeDialog"
    >
      <v-card >
        <v-card-title class="headline primary">
          [{{typeName}}] {{currentItem.name}} - {{dialogTitle}}
          <v-spacer></v-spacer>
          <v-btn v-if="currentMode == 'edit' && tabIndex == 0"
            color="white"
            @click="viewItemForm"
          >
            <v-icon small >details</v-icon> &nbsp;詳細
          </v-btn>
          <v-btn
            v-if="currentMode == 'view' && tabIndex == 0 && $store.state.user.role.startsWith('management')"
            color="white"
            @click="editItemForm"
          >
            <v-icon small >edit</v-icon> &nbsp;編集
          </v-btn>
          <v-btn
            v-if="currentMode != 'new' && !isDeletedItem && tabIndex == 0 && $store.state.user.role.startsWith('management')"
            color="error"
            class="ml-4"
            @click="deleteItem(currentItem)"
          >
            <v-icon small >delete</v-icon> &nbsp;削除
          </v-btn>
          <v-btn
            v-if="isDeletedItem && tabIndex == 0 && $store.state.user.role.startsWith('management')"
            color="info"
            class="ml-4"
            @click="revertItem(currentItem)"
          >
            <v-icon small >delete</v-icon> &nbsp;復元
          </v-btn>
        </v-card-title>
        <v-card-text  style="padding-left: 0;">
          <v-tabs vertical
            @change="onTabChanged"
            height="480px"
          >
            <v-tab>組織詳細</v-tab>
            <v-tab v-if="currentMode == 'view'">所属スタッフ</v-tab>
            <v-tab-item >
              <v-container>
                <v-form ref="form">
                  <v-row dense align="center">
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="currentItem.name"
                        label="医療機関名（必須）"
                        :rules="nameRules"
                        :readonly="currentMode == 'view'"
                        ref="name"
                      />
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="currentItem.postal_code"
                        label="郵便番号（必須）"
                        :rules="postalCodeRules"
                        :readonly="currentMode == 'view'"
                        ref="postal_code"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="currentItem.address"
                        label="住所（必須）"
                        :rules="addressRules"
                        :readonly="currentMode == 'view'"
                        ref="address"
                      />
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="currentItem.phone_number"
                        label="電話番号（必須）"
                        :rules="phoneRules"
                        :readonly="currentMode == 'view'"
                        ref="phone_number"
                      />
                    </v-col>
                    <v-col cols="12" md="4" v-if="$store.state.user.role.startsWith('management')" >
                      <v-select
                        :readonly="currentMode == 'view'"
                        v-model="currentItem.reception_id"
                        :items="this.$store.state.receptions"
                        label="デバイス受付センター"
                        item-text="name"
                        item-value="id"
                      ></v-select>
                    </v-col>
                  </v-row>

                  <!-- <v-row dense class="analysis-opinion-type">
                    <v-col cols="12" md="12">
                      <label>解析/所見</label>
                      <div class="warning--text text-caption">※ 「技師解析」もしくは「医師所見」を選択した場合は、解析時に追加料金が発生します。</div>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-sheet>
                        <v-switch
                          v-model="currentItem.is_contracted_analysis_option"
                          label="オプション解析の契約"
                          :readonly="currentMode == 'view'"
                        ></v-switch>
                      </v-sheet>
                    </v-col>
                    <v-col cols="12" md="8">
                      <v-radio-group
                        v-model="currentItem.analysis_opinion_type"
                        row
                        :readonly="currentMode == 'view'"
                        :disabled="currentItem.is_contracted_analysis_option !== true"
                        mandatory
                      >
                        <v-radio label="自動解析" :value="ANALYSIS_OPINION_TYPES.AUTO"></v-radio>
                        <v-radio label="技師解析" :value="ANALYSIS_OPINION_TYPES.TECHNICIAN"></v-radio>
                        <v-radio label="医師所見" :value="ANALYSIS_OPINION_TYPES.DOCTOR"></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row> -->

                  <v-row dense align="center">
                    <v-col cols="12" md="5">
                      <v-text-field
                        v-model="currentItem.email"
                        label="連絡先Emailアドレス（必須）"
                        :readonly="currentMode == 'view'"
                        :rules="emailRules"
                        ref="email"
                      />
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="currentItem.ec_medical_id"
                        label="医療機関識別No"
                        :readonly="currentMode == 'view'"
                        ref="ec_medical_no"
                      />
                    </v-col>
                    <v-col cols="12" md="12" >
                      <v-textarea
                        v-model="currentItem.remarks"
                        :readonly="currentMode == 'view'"
                        label="備考">
                      </v-textarea>
                    </v-col>
                    <v-col cols="12" md="3" v-if="currentMode == 'view'" >
                      <v-text-field
                        v-model="currentItem.created"
                        label="登録日時"
                        :readonly="true"
                      />
                    </v-col>
                    <v-col cols="12" md="3" v-if="currentMode == 'view'" >
                      <v-text-field
                        v-model="currentItem.modified"
                        label="最終更新日時"
                        :readonly="true"
                      />
                    </v-col>
                    <v-col cols="12" md="3" v-if="currentMode == 'view'" >
                      <v-text-field
                        v-model="currentItem.id"
                        label="ID"
                        :readonly="true"
                      />
                    </v-col>
                  </v-row>

                  <!-- <v-row dense v-if="currentMode == 'view'">
                    <v-col>
                      <v-row dense>
                        <v-col>
                          マイホルターⅡオプション解析 利用規約同意の記録
                        </v-col>
                      </v-row>
                      <v-row dense v-for="(item, key) in currentItem.terms_of_services_for_analysis" :key="key">
                        <v-col cols="12" md="5" >
                          <v-text-field
                            hide-details
                            dense
                            :value="item.label"
                            :readonly="true"
                          />
                        </v-col>
                        <v-col cols="12" md="2">
                          <v-text-field
                            hide-details
                            dense
                            :value="item.version"
                            :readonly="true"
                          />
                        </v-col>
                        <v-col cols="12" md="2">
                          <v-text-field
                            hide-details
                            dense
                            :value="item.datetime"
                            :readonly="true"
                          />
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-text-field
                            hide-details
                            dense
                            :value="item.name"
                            :readonly="true"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row> -->


                </v-form>
              </v-container>
            </v-tab-item>
            <v-tab-item>
              <v-container style="min-height: 680px">
                <v-row v-if="currentMode == 'view'">
                  <v-col cols="12">
                    <customer-staffs :item="currentItem" />
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
          </v-tabs>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="primary darken-1" text @click="closeDialog">閉じる</v-btn>
          <v-btn v-if="currentMode == 'edit'" color="warning darken-1" text @click="onClickActionBtn('saveItem', currentItem)" :disabled="isActionBtnDisabled">保存</v-btn>
          <v-btn v-if="currentMode == 'new'" color="warning darken-1" text @click="onClickActionBtn('newItem', currentItem)" :disabled="isActionBtnDisabled">作成</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import CustomerStaffs from '@/components/CustomerStaffs.vue';

import OrgDialog from '@/mixins/OrgDialog.js';



export default {
  name: 'CustomerOrgDialog',
  components: {
    CustomerStaffs,
  },
  mixins: [
    OrgDialog
  ],
  data() {
    return {
      itemsName: 'customer_orgs',
      type: 'customer',
      tabIndex: 0,
      typeName: '医療機関',
    }
  },
  methods: {
    onTabChanged(num) {
      if (num != 0) this.currentMode = 'view'
      setTimeout(() => {
        this.tabIndex = num;
      }, num == 0? 500: 0)
    }
  }
}
</script>

<style scoped>
  .primary {
    color: white;
  }
  .analysis-opinion-type .v-input--selection-controls {
    margin-top: 0;
  }
</style>