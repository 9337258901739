<template>
  <div class="pa-0">
    <v-container fluid>

      <div class="align-center d-flex mb-2 ml-1 mr-1">
        <h2 text color="black" class="headline">所属スタッフ</h2>
        <div v-if="isAdminRole && !isCenterRole" class="ml-6">
            <v-btn
              color="red font-weight-bold pr-8 pl-8"
              dark
              @click="newItemForm"
            >
              <v-icon dense>mdi-plus</v-icon>新規登録
            </v-btn>
        </div>
      </div>

      <v-toolbar
        color="primary"
        flat
        dark
        height="auto"
        class="pt-3 pb-3 pl-3 rounded"
        v-if="isAdminRole"
      >
        <v-row>

          <v-col cols="12" sm="3" v-if="isManagementRole" class="align-self-end">
            <v-select
              hide-details
              eager
              v-model="filterSelectedField"
              label="削除済み"
              :items="filterFields"
              @change="filterItems"
            ></v-select>
          </v-col>

          <v-col cols="12" sm="3" class="align-self-end">
            <v-select
              hide-details
              eager
              v-model="searchSelectedField"
              label="検索フィールド"
              :items="searchFields"
              @change="subscribeItems"
            ></v-select>
          </v-col>

          <v-col cols="12" sm="6" v-if="searchSelectedField != '権限の種別'" class="align-self-center">
            <v-text-field
              hide-details
              dense
              full-width
              label="前方一致検索キーワード"
              outlined
              clearable
              v-model="searchKeyword"
              prepend-inner-icon="search"
              @blur="subscribeItems"
              @keyup.enter="subscribeItems"
            >
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6" v-if="searchSelectedField == '権限の種別'" class="align-self-center">
            <v-radio-group
              hide-details
              dense
              v-model="accessTypeModel"
              row
              @change="subscribeItems"
            >
              <v-radio label="全てのユーザー" value="all"></v-radio>
              <v-radio label="管理ユーザー" value="admin"></v-radio>
              <v-radio label="一般ユーザー" value="normal"></v-radio>
            </v-radio-group>
          </v-col>

        </v-row>
      </v-toolbar>

      <v-data-table
        dense
        :headers="headers"
        :items="filteredItems"
        :items-per-page.sync=selectedLinesPerPage
        :fixed-header="true"
        :height="isManagementRole ? 400 : isCenterRole ? 500 : 600" 
        hide-default-footer
        :sort-by="sortBy"
        @update:sort-by="updateSortBy"
      >
        <template v-slot:top="{ pagination, options, updateOptions }">
          <v-data-footer
            :pagination="pagination"
            :options="options"
            @update:options="updateOptions"
            items-per-page-text="$vuetify.dataTable.itemsPerPageText"
            :items-per-page-options="linesPerPageList"
            class="border-less"
          />
        </template>

        <template v-slot:item.action="{ item }">

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                @click="viewItemForm(item)"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon class="mx-3">remove_red_eye</v-icon>
              </v-btn>
            </template>
            <span>詳細表示</span>
          </v-tooltip>

          <v-tooltip
            v-if="!isCenterRole"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                @click="editItemForm(item)"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon class="mx-3">edit</v-icon>
              </v-btn>
            </template>
            <span>更新</span>
          </v-tooltip>

          <v-tooltip
            v-if="isAdminRole && !item.deleted && !isCenterRole"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                @click="deleteItem(item)"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon class="mx-3">delete</v-icon>
              </v-btn>
            </template>
            <span>削除</span>
          </v-tooltip>

          <v-tooltip
            v-if="isManagementRole && item.deleted && !isCenterRole"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                @click="revertItem(item)"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon class="mx-3">restore</v-icon>
              </v-btn>
            </template>
            <span>復元</span>
          </v-tooltip>

        </template>

        <template v-slot:item.role="{ item }">
          {{ getRoleName(item.role) }}
        </template>

        <template v-slot:item.is_email_off="{ item }">
          {{ item.is_email_off ? '受信しない': '受信する' }}
        </template>

      </v-data-table>

    </v-container>

    <customer-staff-dialog
      v-if="mode != 'none'"
      v-model="userDialogModel"
      :mode="mode"
      :item="currentItem"
      :org-id="item.id"
      :org-name="item.name"
      @closed="onDialogClosed"
      @not-deleted="itemNotDeleted"
    />

  </div>
</template>

<script>

import CustomerStaffDialog from '@/components/CustomerStaffDialog.vue';
import Staffs from '@/mixins/Staffs.js';

export default {
  name: 'CustomerStaffs',
  components: {
    CustomerStaffDialog
  },
  mixins:[
    Staffs
  ],
  data() {
    return {
      itemsName: 'customer_staffs',
      type: 'customer',
      typeName: '医療機関',
}
  }
}
</script>

<style scoped>

.theme-color {
  color: white;
  background-color:  #B3131A;
}

.top-rounded {
  margin:0px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.border-less {
  border: none !important;
}
</style>